




































































































































import Vue from "vue";
import { includes, find, isNil, keyBy, mapValues } from 'lodash';
import { isDateInputSupported } from '../services/helpers';
import { formatValue } from '../../../shared/v_utils'
import * as Ws from '../services/ws';

import DateAttr from './DateAttr.vue';
import DateThreeInputsAttr from './DateThreeInputsAttr.vue';
import AddressAttr from './AddressAttr.vue';
import ArrayAttr from './ArrayAttr.vue';
import ReadOnlyObjectItems from './ReadOnlyObjectItems.vue';
import cameraSnapshotAttr from './cameraSnapshotAttr.vue';
import PasswordAttr from './PasswordAttr.vue';
import AutocompleteAttr from './AutocompleteAttr.vue';
import CurrentLdapValue from './CurrentLdapValue.vue';
import FileUploadAttr from './FileUploadAttr.vue';

function add_to_oneOf_if_missing(choices: Ws.StepAttrOptionChoices[], to_have) {
    if (!isNil(to_have) && choices && !choices.some(choice => choice.const === to_have)) {
        choices.push({ const: to_have, title: to_have })
    }
    return choices
}

export default Vue.extend({
    props: ['value', 'real_name', 'name', 'opts', 'v', 'ldap_value', 'stepName', 'array_allowed_actions_'],
    components: { 
        DateAttr, DateThreeInputsAttr, ArrayAttr, ReadOnlyObjectItems, AddressAttr, cameraSnapshotAttr, PasswordAttr, AutocompleteAttr, CurrentLdapValue, FileUploadAttr,
        PhotoUploadAttr: () => import('./PhotoUploadAttr.vue'),
    },
    data() {
        return {
            validity: { [this.name]: {} },
            val: this.value,
            initial_value: this.value,
        };
    },
    computed: {
        array_allowed_actions() {
            const allowed = this.array_allowed_actions_ || {}
            return { ...allowed, any: allowed.remove || allowed.move_up || allowed.move_down }
        },
        uiType() {
            if (this.uiOptions.readOnly__avoid_disabled_input && this.opts.readOnly || this.opts.computeValue) {
                return 'span';
            }
            if (this.opts.uiType === 'date' && !isDateInputSupported()) {
                return 'dateThreeInputs';
            }
            return this.opts.uiType || 
                this.opts.items && 'array' ||
                this.opts.oneOf && (this.opts.oneOf.length <= 2 ? 'radio' : 'select') ||
                this.opts.oneOf_async && 'autocomplete';
        },
        uiOptions() {
            return this.opts.uiOptions || {};
        },
        type() {
            return includes(['phone', 'frenchMobilePhone'], this.opts.uiType) ? 'tel' : // "tel" implies inputmode "tel" which is great on mobiles
               this.realType || !this.opts.uiType ? 'text' : 
               this.opts.uiType === 'integer' ? 'number' :
               this.opts.uiType;
        },
        realType() { 
            return includes(['phone', 'frenchMobilePhone', 'frenchPostalCode', 'siret'], this.opts.uiType) ? this.opts.uiType : undefined;
        },
        choicesMap() {
            return this.oneOf && mapValues(keyBy(this.oneOf, 'const'), choice => choice['title']);
        },
        oneOf() {
            const l = add_to_oneOf_if_missing(this.oneOf_, this.opts.allowUnchangedValue)
            return this.opts.max ? l?.filter(choice => choice.const <= this.opts.max) : l
        },
        validValue() {
            return this.uiType === 'select' ? (
                this.oneOf && find(this.oneOf, choice => (
                    // (allow equality if value is number and choice.const is string)
                    choice.const == this.value // tslint:disable-line
                )) 
            ) : this.val;
        },
        computedValue() {
            return this.opts.computeValue?.()
        },
        formattedValue() {
            return formatValue(this.opts.computeValue ? this.computedValue : this.val)
        },
        input_attrs() {
            return this.type === 'password' ? { autocomplete: 'current_password' } : {}
        },
        vue_component_description() {
            if (!this.uiOptions.texts_are_vue_template) return undefined;
            const text = this.opts.description;
            return text && Vue.extend({ props: ['v'], template: "<div>" + text + "</div>" });
        },
    },
    asyncComputed: {
        async oneOf_() {
            const opts = this.opts || {};
            if (opts.oneOf) {
                return opts.oneOf;
            } else if (opts.oneOf_async && ['select', 'radio'].includes(this.uiType)) {
                return await Ws.search(this.stepName, this.real_name || this.name, '');
            } else {
                return undefined;
            }
        },
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            if (this.opts.normalize) {
                const val_ = this.opts.normalize(val);
                if (val_ !== val) { this.val = val = val_ }
            }
            this.$emit('input', val);
        },
    },
});
